import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContentPage, CTASection } from "../components/blocks";
import { Wrapper } from "../components/core";

export default function basicContentPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.sanityBasicContentPage;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={pageData.title} />
      <ContentPage mode="basic" title={pageData.title} blocks={pageData._rawContent ?? []} />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String!, $id: String!) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    sanityBasicContentPage(id: { eq: $id }) {
      title
      _rawContent
    }
  }
`;
